import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const WholeHogBBQ = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const description = "Watch me cook recipes from Sam Jones' Whole Hog BBQ cookbook, which includes Eastern NC style BBQ and traditional sides.";

  const image = getImage(first_video.fields.thumbnail);

  return (
    <Layout>
      <Seo title="Whole Hog BBQ" image={image} meta={metaData} description={description} />
      <h1>Whole Hog BBQ</h1>
      <h2>The Gospel of Carolina Barbecue</h2>
      <p>For <a href="https://www.samjonesbbq.com/">Sam Jones</a>, BBQ is a legacy.
      His grandfather opened <a href="https://www.skylightinnbbq.com/">the Skylight Inn</a> in
      a tiny town called Ayden in North Carolina in 1947. It still exists today, run by the family,
      still serving the same whole hog bbq, smoked over wood coals, and a very small list of
      sides. He's opened his own restaurant near Greenville, and is working on one in Raleigh.</p>
      <p>Daniel Vaughn is the Barbecue Editor at <a href="https://www.texasmonthly.com/author/daniel-vaughn/">
      Texas Monthly</a>. Previously an architect, he turned his love for barbecue into his 
      profession in 2013, the same year he published <a href="https://www.harpercollins.com/9780062202925/the-prophets-of-smoked-meat/">
      The Prophets of Smoked Meat: A Journey Through Texas Barbecue</a>. Texas barbecue differs
      a lot from the Eastern NC style, but they both are founded on meat slow-cooked over wood.</p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/whole hog bbq/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default WholeHogBBQ;